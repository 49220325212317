import { render, staticRenderFns } from "./infoCardList2.vue?vue&type=template&id=3b947b08&scoped=true&"
import script from "./infoCardList2.vue?vue&type=script&lang=js&"
export * from "./infoCardList2.vue?vue&type=script&lang=js&"
import style0 from "./infoCardList2.vue?vue&type=style&index=0&id=3b947b08&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b947b08",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VChipGroup,VIcon})
