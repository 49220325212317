<template>
	<div id="PieChart">
    <v-card flat :height="height_card"> 
      <v-card-title class="justify-center">
        <v-icon
          medium
          left
          >
         {{icon}}
        </v-icon>
        <h3 class="font-weight-medium">{{data_chart.title}}</h3>
      </v-card-title>
      <v-card-text style="margin-top:-10px;">
       <apexchart type="pie" :width="width" ref="chart" 
        :height="height_chart" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
      </v-card-text>
    </v-card>
	</div>
</template>

<script>
export default {

  name: 'PieChart',
  props: ['width','height_chart','height_card','data_chart','icon'],

  data () {
    return {
    	series: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        
        legend:{
        	position: 'bottom',
        	horizontalAlign: 'center', 
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          offsetY: 300,
          style: {
              fontSize: '13px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
              colors: ['white']
          },
          formatter: function (val, opts) {
            if (val > 8){
              return val.toFixed(1)+'%'
            }
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10
            }
          }
        },
      },
    }
  },
  mounted(){
  	this.updateChart();
  },
  computed:{
  	updateData(){
  		this.data_chart;
  	}
  },
  watch:{
  	updateData(){
  		this.updateChart();
  	}
  },
  methods:{
  	updateChart(){
      let position_legend;
      
      if( this.height_card <= 200){
        position_legend = 'right';
      }
      else{
        position_legend = 'bottom';
      }

  		this.$refs.chart.updateOptions({
  			series:this.data_chart.serie,
  			labels: this.data_chart.categories,
  			colors:this.data_chart.colors,
        legend:{
          position: position_legend
        }
  		});
  	}
  }
}
</script>

<style lang="css" scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>