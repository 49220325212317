<template>
	<div id="chart">
    <apexchart type="bar" :height="height_chart"  ref="chart" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
  </div>
</template>

<style lang="css" scoped>
div.chart-wrapper {
		
		margin-left:-20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<script>
export default {

  name: 'stackColumn',
  props:[
  	'height_chart',
  	'data_chart'
  ],
  data () {
    return {
    	series: [{
        name: 'Si',
        data: [44]
      },{
        name: 'No',
        data: [49]
      },{
        name: 'En proceso',
        data: [30]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar:{
          	show:false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
          },
        },
        stroke: {
          width: 0.5,
          colors: ['#fff']
        },
        colors:[
	        '#001970',
	        '#b91400',
	        '#373737'
	      ],
        xaxis: {
          categories: [2008],
          labels: {
          	show: false,
        	},
        	axisBorder: {
		          show: false,
		          color: '#78909C',
		          height: 1,
		          width: '100%',
		          offsetX: 0,
		          offsetY: 0
		      },
		      axisTicks: {
		          show: false,
		          borderType: 'solid',
		          color: '#78909C',
		          height: 6,
		          offsetX: 0,
		          offsetY: 0
		      },
        },
        yaxis:{
        	labels:{
        		show:false
        	},
        	axisBorder: {
	          show: false,
	          color: '#78909C',
	          height: 1,
	          width: '100%',
	          offsetX: 0,
	          offsetY: 0
		      },
		      axisTicks: {
	          show: false,
	          borderType: 'solid',
	          color: '#78909C',
	          height: 6,
	          offsetX: 0,
	          offsetY: 0
		      },
        },
        tooltip: {
        	theme:'dark',
        	custom: function({series, seriesIndex, dataPointIndex, w}) {
        		let seriesNames = w.globals.seriesNames;
        		let total = (data) => {
        			let total = 0;
        			data.forEach(element => total += element[0]);
        			return total
        		};

        		let total_series = total(series);

        		let name = seriesNames[seriesIndex];
        		let value = series[seriesIndex][dataPointIndex];
						let porcentaje = ((value/total_series)*100).toFixed(1);
 
				    return '<div style="width:120px;height:130px;">' +
				      '<div class="ml-2 mt-2 font-weight-bold body-1">' + name + '</div>' +
				      '<div class="ml-2 mt-1 font-weight-light subtitle-2">Resultados</div>' +
				      '<div class="ml-2 font-weight-bold body-1" style="margin-top:-5px;">' + value + '</div>' +
				      '<div class="ml-2 mt-1 font-weight-light subtitle-2">Porcentaje</div>' +
				      '<div class="ml-2 font-weight-bold body-1" style="margin-top:-5px;">' + porcentaje + '%</div>' +
				      '</div>'
				  },
          y: {
            formatter: function (val) {
              return val 
            }
          }
        },
        fill: {
          opacity: 1
        
        },
        title: {
				    text: 'Opciones',
				    align: 'center',
				    margin: 0,
				    offsetX: 0,
				    offsetY: 15,
				    floating: false,
				    style: {
				      fontSize:  '14px',
				      fontWeight:  'light',
				      fontFamily:  undefined,
				      color:  '#263238'
				    },
				},
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '14px',
		      fontFamily: 'Helvetica, Arial',
		      fontWeight: 400,
		      offsetX:10,
		      itemMargin: {
	          horizontal: 10,
	          vertical:5
		      },
        },
        dataLabels: {
				  enabled: true,
				  enabledOnSeries: undefined,
				  formatter: function (val, opts) {
				  	if(val > 6){
				     return val.toFixed(1)+'%'
				  	}
				  	else{
				  		return ''
				  	}
			  	},
			  }
      },
    }
  },
  mounted(){
  	this.updateChart();
  },
  computed:{
  	updateData(){
  		return this.data_chart;
  	}
  },
  watch:{
  	updateData(){
  		this.updateChart();
  	}
  },
  methods:{
  	updateChart(){
  		this.$refs.chart.updateOptions({
  			series: this.data_chart.series,
  			xaxis:{
  				categories: this.data_chart.categorias
  			}
  		});
  	}
  }
}
</script>

<style lang="css" scoped>
</style>