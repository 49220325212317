var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"totalView"}},_vm._l((1),function(n){return _c('v-row',{key:n},_vm._l((n+2),function(k){return _c('v-col',{key:k,attrs:{"cols":_vm.width_cols[k]}},[(k === 1)?_c('div',[(_vm.data_dashboard[1].cant_datos < 16)?_c('div',[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[0].selected,"data_properties":{
            icon: 'mdi-information',
            title: 'Participación DOM',
            data_list: _vm.data_dashboard[0].data_list,
            width_chart: 300,
            height_chart:300,
            height_card:340
          }}})],1):_c('div',[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[0].selected,"data_properties":{
          icon: 'mdi-information',
          title: 'Participación DOM',
          data_list: _vm.data_dashboard[0].data_list,
          width_chart: 315,
          height_chart:315,
          height_card:195
          }}})],1),_c('selectGraph',{staticClass:"mt-3",attrs:{"selected_graph":_vm.data_dashboard[1].selected,"data_properties":{
          icon:'mdi-information',
          title:'Participación DOM',
          subtitle:'Regional',
          data_list:_vm.data_dashboard[1].data_list,
          height_card:'100%'
        }}})],1):_vm._e(),(k === 2)?_c('div',[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[2].selected,"data_properties":{
          icon: 'mdi-human-male-female',
          title: _vm.data_dashboard[2].variable,
          var_1: _vm.data_dashboard[2].variable,
          data_var_1: _vm.format(_vm.data_dashboard[2].total),
          data_list: _vm.data_dashboard[2].data_list,
          options:['Mayor cantidad','Menor cantidad'],
          tipo_data:'Funcionarios',
          height_card: 340,
          height_chart:330
        }}}),_c('selectGraph',{staticClass:"mt-3",attrs:{"selected_graph":_vm.data_dashboard[3].selected,"data_properties":{
          icon:'mdi-information',
          title:_vm.data_dashboard[3].variable,
          subtitle:'',
          data_list:_vm.data_dashboard[3].data_list,
          width_chart: 335,
          height_card:200,
          height_chart:335,
        }}}),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('selectGraph',{staticClass:"mt-3",attrs:{"selected_graph":_vm.data_dashboard[4].selected,"data_properties":{
              icon: 'mdi-information',
              title: _vm.data_dashboard[4].variable,
              subtitle: 'DOM sin PRC',
              data_list: _vm.data_dashboard[4].data_list,
              height_card: 240,
              height_chart: 200
            }}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('selectGraph',{staticClass:"mt-3",attrs:{"selected_graph":_vm.data_dashboard[5].selected,"data_properties":{
              icon:'mdi-information',
              title:_vm.data_dashboard[5].variable,
              subtitle:_vm.data_dashboard[5].variable,
              data_list:_vm.data_dashboard[5].data_list,
              height_card:240,
              height_chart:180
            }}})],1)],1)],1):_vm._e(),(k === 3)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[6].selected,"data_properties":{
              icon:'mdi-office-building',
              title:_vm.data_dashboard[6].variable,
              subtitle:'',
              data_list:_vm.data_dashboard[6].data_list,
              width_chart: 315,
              height_card:300,
              height_chart:315,
            }}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[7].selected,"data_properties":{
                icon: 'mdi-office-building',
                title: _vm.data_dashboard[7].variable,
                var_1: '',
                data_var_1: '',
                data_list: _vm.data_dashboard[7].data_list,
                tipo_data:'Ranking',
                options:['Mejor Infraestructura','Peor Infraestructura'],
                height_card: 300,
                height_chart:320
              }}})],1)],1),_c('v-row',{staticStyle:{"margin-top":"-5px"}},[_c('v-col',[(_vm.data_dashboard.length === 10)?_c('div',[_c('selectGraph',{attrs:{"selected_graph":'doble_chart_select',"data_properties":{
                data_var_1:_vm.data_dashboard[8],
                data_var_2:_vm.data_dashboard[9],
                icon:'mdi-school',
                title: 'Capacitación',
                height_card:490,
                height_chart:380,
                width_chart:790,
              }}})],1):_c('div',[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[8].selected,"data_properties":{
                icon:'mdi-school',
                title: _vm.data_dashboard[8].variable,
                data_list: _vm.data_dashboard[8].data_list,
                height_card:490,
                height_chart:420,
                width_chart:790,
              }}})],1)])],1)],1):_vm._e()])}),1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }