<template>
	<div>
		<v-card flat :height="height_card">
			<v-card-title class="justify-center">
				<v-icon
        medium
        left
        >
	       {{icon}}
	      </v-icon>
				<h3 class="font-weight-medium">
					{{title}}
				</h3>
			</v-card-title>
			<v-card-text >
				<TreeMap 
          style="margin-top:-30px;"
          :data="get_data_chart(data_chart)"
          :height="height_chart"
    			:width="'100%'" 
        />
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import TreeMap from '../charts/TreeMap.vue';

export default {

  name: 'infoCardTreeMap',
  props:[
  	'title','icon','data_chart','height_card',
    'height_chart'
  ],
  components:{
  	TreeMap
  },

  data () {
    return {

    }
  },

  methods:{
  	get_data_chart(data){
  		let colors = [
        '#0069c0',
        '#001970',
        '#00695c',
        '#d81b60',
        '#b91400',
        '#373737'
      ];
  		let element;
  		let serie = [];

  		for (element of data){
  			let value = element.cant;
  			let option = element.option;

        if( option == 'Correo Electrónico' ){
          option = 'Email'
        }

  			let temp = {
  				x: option,
  				y: value
  			}

  			serie.push(temp)
  		}


  		serie.sort((a,b) => (a.y < b.y) ? 1: -1)
  		
  		return {
  			serie:serie,
  			colors:colors.slice(0,serie.length)
  		}
  	}
  }
}
</script>

<style lang="css" scoped>
</style>