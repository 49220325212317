<!-- Donut Chart Info Card Component -->
<!-- Cambiar -->
<template>
	<div>
		<v-card flat :height="height_card">
      <v-card-title class="justify-center" >
        <v-icon
          medium
          left
          >
         {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{data_chart.title}}</h4>
      </v-card-title>
      <v-card-text style="margin-top:-15px;">
        <apexchart type="donut"  ref="chart"
          :width="width" 
          :height="height_chart" 
          :options="chartOptions" 
          :series="series" 
          class="chart-wrapper">
        </apexchart>
      
      </v-card-text>
    </v-card>
	</div>
</template>

<script>
export default {

  name: 'DonutChart',
  props: ['width','height_chart','height_card','data_chart','icon'],
  data () {
    return {
    	series: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
      
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       width: 200
        //     },
        //     legend: {
        //       position: 'bottom'
        //     }
        //   }
        // }]
      },
    }
  },
  mounted(){
    this.updateChart();
  },
  computed:{
  	updateData(){
  		return this.data_chart;
  	},
    
  },
  watch:{
  	updateData(){
  		this.updateChart();
  	},
  },
  methods:{
  	updateChart(){

      let position_legend;
      let text_size, text_size_2;

      if( (this.height_card <= 385 && this.height_card > 250 ) && this.data_chart.serie.length <= 2){
        position_legend = 'bottom';
        text_size='12px';
      }
      else if(( this.height_card > 385 &&  this.height_card <= 450 ) && this.data_chart.serie.length <= 4){
        position_legend = 'bottom'
        text_size = '13px'
        text_size_2  = '17px'
      }
      else if( this.height_card <= 250 && this.data_chart.serie.length <= 2 ){
        position_legend = 'right'
        text_size = '10px';
        text_size_2  = '11px'

      }
      else{
        position_legend = 'right';
        text_size = '14px';
      }


  		this.$refs.chart.updateOptions({
  			series:this.data_chart.serie,
  			labels: this.data_chart.categories,
  			colors:this.data_chart.colors,
        legend:{
          position: position_legend,
          horizontalAlign: 'center', 
          floating: false,
          fontSize: text_size,
          fontFamily: 'Helvetica, Arial',
          fontWeight: 545,
          itemMargin: {
            horizontal: 5,
            vertical:5
          },
        },
        dataLabels:{
          enabled:false,
          formatter: function (val, opts) {
            if (val > 8){
              return val.toFixed(1)+'%'
            }
          },
          style: {
              fontSize: text_size,
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
              colors: ['white']
          },
        },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {

            let total = series.reduce((a, b) => {
              return a + b
            }, 0);

            let value = series[seriesIndex];

            let porcentaje = ((value/total)*100).toFixed(2);
            
            let label = w.globals.labels[seriesIndex];

            let text = value+'/'+total;


            return '<div class="arrow_box" style="width:150px;height:75px;">' +
              '<div class="subtitle-1 ml-1  font-weight-medium">' + label + '</div>' +
              '<div class="body-1 ml-1" style="margin-top:-5px;">' +porcentaje+'%'+'</div>' +
              '<div class="subtitle-2 ml-1" style="margin-top:0px;">' +text  + '</div>' +
              
              '</div>'
          }
        },
        plotOptions: {
          pie: {
           donut:{
            size: '65%',
            labels: {
              show:true,
              name:{
                fontSize: '11.5px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: 0,
                formatter: function (val) {
                  return val
                }
              },
              value: {
                show: true,
                fontSize: text_size_2,
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                offsetY: 0,
                formatter: function (val,w) {
                  let total = w.globals.seriesTotals.reduce((a, b) => {
                                return a + b
                              }, 0);

                  let porcentaje = (val/total)*100;
                  porcentaje = (porcentaje.toFixed(2)) + '%';
                  let text = val+'/'+total;
                  return text
                }
              },
              total: {
                show: true,
                showAlways: false,
                label: this.data_chart.serie.length == 2 ? 'Participación' :  'Total' ,
                fontSize: text_size_2,
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 450,
                color: '#373d3f',
                formatter: function (w) {

                  let data = w.globals.seriesTotals;

                  if(data.length == 2){
                    let total = data.reduce((a, b) => {
                      return a + b
                    },0);

                    let positivo = data[0];
                    let text = positivo+'/'+total;

                    let porcentaje = ((positivo/total)*100).toFixed(2);

                    return porcentaje+'%'
                  }
                  else{
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                  
                }
              }
            }
           }
          }
        },
  		});
  	}
  }
}
</script>

<style lang="css" scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>