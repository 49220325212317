<template>
  <div id="new_project">
    <div id="header">
      <v-app-bar color="blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-light">Crear Proyecto</h2>
      </v-app-bar>
    </div>
    <div id="body">
      <v-card class="fill-height" flat>
      <v-card-text>
        <v-container  fill-height class="my-16">
          <v-layout align-center justify-center>
            <v-flex style="max-width:450px" class='text-center display-1 mb-3' >
              <v-form 
                ref="form_proyect" v-model="valid"
              >
                <div style="width:90%;" class="mb-6">
                 <v-file-input
                  v-model="file"
                  color="deep-purple accent-4"
                  counter
                  label="Selecciona el archivo .xlsx"
                  class="mt-5 text-center ml-10"
                  placeholder="Archivo"
                  prepend-icon="mdi-file-plus-outline"
                  outlined
                  :show-size="1000"
                  required
                  :rules= "[v => !!v || 'Debe subir un archivo.']"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>
                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </div>

              <v-text-field
                v-model="nombre_proyecto"
                :counter="20"
                label="Nombre proyecto"
                required
                outlined
                :rules="[v => !!v || 'Debe ingresar un nombre para el proyecto',  v => (v && v.length <= 20) || 'El nombre no puede tener más de 20 caracteres']"
              />
              <v-text-field
                v-model="titulo_proyecto"
                :counter="40"
                label="Título proyecto"
                required
                outlined
                :rules="[v => !!v || 'Debe ingresar un nombre para el proyecto',  v => (v && v.length <= 40) || 'El nombre no puede tener más de 40 caracteres']"
              />
            <v-checkbox
              v-model="anuales"
              label="¿Datos anuales?"
            ></v-checkbox>
            <v-expand-transition>
              <div v-if="anuales" class="mx-auto" style="width:200px;">
                <v-row>
                  <v-col cols="6">
                    <v-switch v-model="anio_fijo" @click="anio_intervalo = false">
                      <template v-slot:label>
                        Fijo
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-switch v-model="anio_intervalo" @click="anio_fijo = false">
                      <template v-slot:label>
                        Intervalo
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div v-if="anio_fijo && anuales" style="width:150px;" class=" mx-auto">
                <v-row>
                  <v-col cols=12>
                    <v-text-field
                      v-model="anio1"
                      :counter="4"
                      label="Año"
                      dense
                      required
                      outlined
                      :rules="[v => !!v || 'Debe ingresar un año',  v => (v && v.length <= 4) || 'Ej: 2021']"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="anio_intervalo && anuales" style="width:300px;" class=" mx-auto">
                <v-row class="ml-14">
                  <v-col cols=5>
                    <v-text-field
                      v-model="anio1"
                      :counter="4"
                      label="Año"
                      dense
                      required
                      outlined
                    />
                  </v-col>
                  -
                  <v-col cols=5>
                    <v-text-field
                      v-model="anio2"
                      :counter="4"
                      label="Año"
                      dense
                      required
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
              <div class="text-center mt-7">
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="submitFile()"
                >
                  Crear proyecto
                </v-btn>
                <v-btn
                  color="error"
                  class="mr-4"
                  
                >
                  <!-- @click="reset" -->
                  Borrar datos
                </v-btn>
              </div>
            </v-form>
            <div v-if="error" >
              <v-alert type="error" class="text-left mt-5" height=55 transition="fade-transition">
                <h3 class="font-weight-medium" style="margin-top:-7px;">Error</h3>
              </v-alert>
            </div>
             <div v-else-if="success" >
              <v-alert type="success"  class="text-left mt-5" height=55  transition="fade-transition">
               <h3 class="font-weight-medium" style="margin-top:-7px;">Archivo procesado</h3>
              </v-alert>
            </div>
          </v-flex>
        </v-layout>

      </v-container>
      <div v-if="loading" class="text-center" style="margin-top:-40px;">
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
     
    </v-card-text>
    </v-card>
  </div>
</div>
</template>


<style>
div.centrar{
  width:100%;
}

#new_project {
  display: flex;
  flex-direction: column;
  height: 88.5vh;
}

#header {
  height: 50px;
  margin-bottom: 20px;
}

#body {
  flex-grow: 1;
}

</style>

<script>

  import axios from 'axios';
  // const API_URL = 'http://localhost:8000/'

  const API_URL = 'https://api.plus.datari.net/'
  


  export default {
    name: 'new_proyect',
    props:['cantidad_proyectos','user_id'],
    data: () => ({
      valid:false,
      titulo_proyecto: '',
      nombre_proyecto:'',
      anio1:'',
      anio2:'',
      region: false,
      comuna:false,
      anuales:false,
      file: null,
      loading:false,
      anio_fijo:false,
      anio_intervalo:false,
      error:false,
      success:false
    }),
    methods: {

      get_propiedades(){
        let titulo = this.titulo_proyecto;
        let anio1,anio2;
        let data;
        if(this.anuales){
          if(this.anio_fijo){
            anio1 = this.anio1;
            data = {
              'titulo': titulo,
              'tipo': 'fijo',
              'anios': {
                'anio': anio1
              },
          }
        }
          else if(this.anio_intervalo){
            anio1 = this.anio1;
            anio2 = this.anio2;
            data = {
              'titulo': titulo,
              'tipo': 'intervalo',
              'anios': {
                'anio_i': anio1,
                'anio_f':anio2
              }
            }

          }
        }
        else{ 
          data = {
              'titulo': titulo,
              'tipo': 'no_anios',
          }
        }
        return data;
      },

      submitFile(){

        this.loading = true;
        this.$refs.form_proyect.validate();
        // let propiedades = this.get_propiedades();
        let id_user = this.user_id;
        let nombre_proyecto = this.nombre_proyecto;
        let titulo_proyecto = this.titulo_proyecto;
        let upload_file = new FormData();
        upload_file.append("file_uploaded", this.file);
        upload_file.append("id_user", id_user);
        upload_file.append("project_name", nombre_proyecto);
        upload_file.append("project_title", titulo_proyecto);
        axios({
          url:API_URL+'file_management/upload/', 
          data: upload_file,
          method: 'POST',
          headers: {
            // 'Access-Control-Allow-Origin':'https://localhost:8080',
            'Content-Type': 'multipart/form-data',
            // 'Accept':'*/*',

          }
        })
        .then(response => {
          let data = response.data;
          let nombre_proyecto = data.proyecto_nombre;
          let numero_proyecto = data.numero_proyecto;
          // let dashboards_proyecto = data.dashboards;
          this.sleep(3).then(() => {
            this.loading = false;
            this.success = true;
            this.sleep(1)
            .then(() => {
              this.$router.push({
                path:'/projects/new_project/select_graphs/',
                name:'projectOptions',
                params:{'data_proyecto':data,'nombre_proyecto':nombre_proyecto,'numero_proyecto':numero_proyecto}
              });
            });
          });
          console.log('SUCCESS!!');
        })
        .catch(e => {
          console.log('FAILURE!!');
          console.log(e)
          this.sleep(3).then(() => {
            this.loading  = false;
            this.error = true;
          });
          
        });
        
      },

      sleep(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds*1000));
      }, 
  }
}
</script>

