var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"totalViewVariables"}},_vm._l((1),function(n){return _c('v-row',{key:n},_vm._l((n+2),function(k){return _c('v-col',{key:k,attrs:{"cols":_vm.width_cols[k]}},[(k === 1)?_c('div',[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[0].selected,"data_properties":{
	          data_list: _vm.data_dashboard[0].data_list,
	          icon:'mdi-desktop-classic',
	          width_chart: 490,
	          height_chart:300,
	          height_card: 360,
	          title:_vm.data_dashboard[0].variable
	        }}}),_c('selectGraph',{staticClass:"mt-3",attrs:{"selected_graph":_vm.data_dashboard[1].selected,"data_properties":{
            height_card: 430,
            height_chart:350,
            width_chart:520,
            title: _vm.data_dashboard[1].variable,
            icon: 'mdi-desktop-classic',
            data_list: _vm.data_dashboard[1].data_list
          }}})],1):_vm._e(),(k == 2)?_c('div',[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[2].selected,"data_properties":{
            icon: 'mdi-desktop-classic',
            title: _vm.data_dashboard[2].variable,
            var_1: _vm.data_dashboard[2].variable,
            data_var_1: _vm.format(_vm.data_dashboard[2].total),
            data_list:_vm.data_dashboard[2].data_list,
            tipo_data: 'computadores',
            options:['Mayor cantidad','Menor cantidad'],
            height_card:360,
            height_chart:330
          }}}),_c('selectGraph',{staticClass:"mt-3",attrs:{"selected_graph":_vm.data_dashboard[4].selected,"data_properties":{
                height_card:430,
                height_chart:350,
                width_chart:400,
                title:_vm.data_dashboard[4].variable,
                icon:'mdi-desktop-classic',
                data_list: _vm.data_dashboard[4].data_list
              }}})],1):_vm._e(),(k == 3)?_c('div',[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[5].selected,"data_properties":{
            icon:'mdi-desktop-classic',
            title:_vm.data_dashboard[5].variable,
            data_list: _vm.data_dashboard[5].data_list,
            height_card:400,
            height_chart:350
          }}}),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[6].selected,"data_properties":{
                title:_vm.data_dashboard[6].variable,
                icon: 'mdi-desktop-classic',
                data_1: _vm.data_dashboard[6].data_list[1],
                data_2:_vm.data_dashboard[6].data_list[0],
              }}}),_c('selectGraph',{staticClass:"mt-3",attrs:{"selected_graph":_vm.data_dashboard[3].selected,"data_properties":{
            		title:_vm.data_dashboard[3].variable,
            		icon: 'mdi-desktop-classic',
            		data_list: _vm.data_dashboard[3].data_list,
            		height_card:216,
            		height_chart:140
            	}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('selectGraph',{attrs:{"selected_graph":_vm.data_dashboard[7].selected,"data_properties":{
                data_list: _vm.data_dashboard[7].data_list,
                title: _vm.data_dashboard[7].variable,
                icon: 'mdi-desktop-classic',
                width_chart: 300,
                height_chart: 300,
                height_card:390
              }}})],1)],1)],1):_vm._e()])}),1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }