<template>
	<v-card flat>
		<v-card-title class="justify-center">
			<v-icon
        medium
        left
        >
       {{icon}}
      </v-icon>
      <h4 class="font-weight-medium">{{title}}</h4>
		</v-card-title>
		<v-card-text class="text-center" style="margin-top:-5px;">
      <h3 class="font-weight-light">{{name1}}</h3>
			<h2 class="font-weight-bold mt-2">{{data1}} <small class="font-weight-light">{{data_type}}</small></h2>
			<h3 class="font-weight-light mt-3">{{name2}}</h3>
			<h2 class="font-weight-bold mt-2">{{data2}} <small class="font-weight-light">{{data_type}}</small></h2>
		</v-card-text>
	</v-card>
</template>

<script>
export default {

  name: 'infoCardDoble',
  props: ['title','data1','name1','data2','name2','icon','data_type'],
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>