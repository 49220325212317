<template>
	<div id="chart">
    <apexchart type="treemap" 
    	:height="height"
    	width='100%'
    	ref="chart" 
    	:options="chartOptions" 
    	:series="series"
    	class="chart-wrapper"
    >
    </apexchart>
  </div>
</template>

<script>
export default {

  name: 'TreeMap',
  props:[
  	'data',
  	'width',
  	'height'
  ],
  data () {
    return {
    	series: [
      {
        data:[]
      }
    ],
    chartOptions: {
      legend: {
        show: false
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar:{
        	show:false
        }
      },
      colors:[],
      plotOptions: {
	      treemap: {
	        distributed: true,
	        enableShades: false
	      }
	    },

      dataLabels: {
          enabled: true,
          style: {
            fontSize: '25px',
             fontWeight: 'medium',
          },
          formatter: function(text, op) {
            if(text.length < 35){
              return text
            }
            else if(op.value > 0 && op.value <=12 && text.length < 35){
              return text
            }
                 
          },
      },
      tooltip: {
        theme:'dark',
        custom: function({series, seriesIndex, dataPointIndex, w}) {

          let categoryLabels = w.globals.categoryLabels;
          let option = categoryLabels[dataPointIndex]
          let width_card = option.length > 50 ? '400px': 
                            option.length > 20 && option.length <= 50 ? '300px' :
                            '150px';

          // Total segun valores de serie
          let total = series[0].reduce((a, b) => {
            return a + b
          }, 0);


          // console.log
          let value = series[seriesIndex][dataPointIndex];

          let porcentaje = ((value/total)*100).toFixed(2);
          
          let text = value+'/'+total;


          return '<div class="arrow_box" style="width:'+width_card+';height:90px;">' +
            '<div class="subtitle-1 ml-1  font-weight-medium">' + option + '</div>' +
            '<div class="subtitle-1 font-weight-light ml-1" style="margin-top:0px;">' +text + '</div>' +

            '<div class="body-1 ml-1" style="margin-top:0px;">' +porcentaje+'%'+'</div>' +
           
            
            '</div>'
        }
      },
    }
   }
 	},
 	mounted(){
  	this.updateChart();
  },
  computed:{
  	updateData(){
  		return this.data;
  	}
  },
  watch:{
  	updateData(){
     	this.updateChart();  		
  	}
  },
  methods:{
    updateChart(){
     
    this.$refs.chart.updateOptions({
       series: [{
         data: this.data.serie
       }],

       colors:this.data.colors,
     });


      this.chartOptions.colors = this.data.colors;
    },
    


  	// updateChart(){
  	// 	this.$refs.chart.updateOptions({
  	// 		serie: [{
  	// 			data: this.data.serie
  	// 		}],
  	// 		colors:this.data.colors,
  	// 	});
  	// }
  }
}
</script>
<style lang="css" scoped>
div.chart-wrapper {
		margin-top:0px;
		margin-left:0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>