<template>
	<v-card flat>
		<v-card-title class="justify-center">
			<v-icon
        medium
        left
        >
       {{icon}}
      </v-icon>
      <h4 class="font-weight-medium">{{title}}</h4>
		</v-card-title>
		<v-card-text class="text-center">
			<h1 class="font-weight-BOLD">{{data}}</h1>
      <h3 class="font-weight-light mt-2">{{data_type}}</h3>
		</v-card-text>
	</v-card>
</template>

<script>
export default {

  name: 'infoCard',
  props: ['title','data','icon','data_type'],
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>