<template>
	<div>
		ola
	</div>
</template>

<script>
export default {

  name: 'infoCardGroupChip',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>